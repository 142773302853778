/*----------------------------------------*/
/*  15 - Components - Product
/*----------------------------------------*/
.torress-product_area {
	padding-top: 85px;
	.banner-item {
		border: 1px solid $border-color;
		@include breakpoint (max-small) {
			margin-bottom: 25px;
		}
		> a {
			> img {
				width: 100%;
			}
		}
	}
}

/* ---Torress's Product Slider--- */
.torress-product_slider,
.torress-product_slider-2,
.torress-product-tab_slider,
.torress-product-tab_slider-2,
.torress-product-tab_slider-3,
.shop-product-wrap {
	.slide-item {
		.single-product {
			padding: 35px 15px 30px;
			@extend %basetransition;
			.product-img {
				position: relative;
				text-align: center;
				> a {
					> img {
						width: 100%;
					}
				}
				.add-actions {
					background-color: $bg-snow_color;
					box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
					transform: translateX(0);
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					@extend %basetransition;
					z-index: 9;
					> ul {
						> li {
							display: block;
							> a {
								display: block;
								width: 40px;
								height: 40px;
								line-height: 40px;
								text-align: center;
								&:hover {
									background-color: $primary-color;
									color: $bg-white_color !important;
								}
								> i {
									font-size: 20px;
								}
							}
						}
					}
				}
			}
			.product-content {
				padding-top: 30px;
				.product-desc_info {
					.price-box {
						@include new-price;
						@include old-price;
					}
					> h6 {
						font-weight: $body-font_weight;
						&.product-name {
							margin-bottom: 0;
							padding-bottom: 15px;
							font-size: 17px;
							@include breakpoint(normal) {
								font-size: 13px;
							}
							@include breakpoint(medium) {
								font-size: 13px;
							}
						}
					}
					.rating-box {
						padding-bottom: 10px;
						> ul {
							> li {
								display: inline-block;
								> i {
									color: $primary-color;
									font-size: 18px;
								}
							}
						}
					}
				}
			}
			&:hover {
				.product-img {
					.add-actions {
						transform: translateX(20px);
						opacity: 1;
					}
				}
			}
		}
	}
	.slick-list {
		margin: 0;
		.slick-slide {
			padding: 0;
		}
	}
}

/* ---Torress's Product Slider Two--- */
.torress-product_slider-2 {
	.slick-list {
		margin: 0 -15px;
		.slick-slide {
			padding: 0 15px;
		}
	}
}

/* ---Torress's Countdown--- */
.torress-countdown {
	border: 2px solid $primary-color;
	border-radius: 50px;
	text-align: center;
	.count {
		display: inline-block;
		position: relative;
		width: 20%;
		padding: 5px 0;
		span {
			&.count-amount {
				display: block;
				font-weight: 600;
				font-size: 18px;
				@include breakpoint(normal) {
					font-size: 15px;
				}
			}
			&.count-period {
				display: block;
				font-size: 14px;
				text-transform: uppercase;
				@include breakpoint(normal) {
					font-size: 13px;
					text-transform: capitalize;
				}
			}
		}
	}
}
