/*----------------------------------------*/
/*  39 - Pages - About Us
/*----------------------------------------*/
.main-content_area {
	padding: 90px 0 0;
	.about-us_area {
		.row {
			align-items: center;
		}
		.about-us_img {
			position: relative;
			overflow: hidden;
			display: flex;
			align-items: center;
			img {
				&.secondary-img {
					box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
				}
			}
		}
		.about-us_content {
			@include breakpoint (max-medium) {
				padding: 85px 0 0;
			}
			> h3 {
				&.heading {
					margin-bottom: 0;
					font-size: 36px;
					font-weight: $heading-font_weight-2;
				}
			}
			> p {
				max-width: 60%;
				@include breakpoint (max-small) {
					max-width: 100%;
				}
				&.short-desc {
					padding-top: 10px;
					margin-bottom: 0;
				}
				&.additional-desc {
					padding-top: 10px;
					margin-bottom: 0;
				}
			}
		}
	}
	.mission-area {
		padding: 85px 0 0;
		h3 {
			&.mission-heading {
				margin: 0 auto;
				text-align: center;
				padding-bottom: 25px;
				font-size: 36px;
				font-weight: $heading-font_weight-2;
			}
		}
		.mission-img_area {
			position: relative;
			background-image: url('../images/about-us/3.jpg');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			min-height: 570px;
			&:before {
				content: "";
				position: absolute;
				background-color: rgba($heading-color, .6);
				width: 100%;
				height: 100%;
				opacity: 0;
				visibility: hidden;
				@extend %basetransition;
			}
			&:hover {
				&:before {
					opacity: 1;
					visibility: visible;
				}
			}
			> a {
				@include absCenter;
				border-radius: 100%;
				border: 1px solid $bg-white_color;
				width: 80px;
				height: 80px;
				line-height: 80px;
				display: block;
				text-align: center;
				@extend %basetransition;
				&:hover {
					background-color: $primary-color;
					border-color: $primary-color;
				}
				> i {
					font-size: 45px;
					color: $bg-white_color;
					padding-left: 10px;
				}
			}
		}
	}
	.testimonial-area {
		position: relative;
		background-image: url('../images/about-us/testimonial/bg-1.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		padding: 90px 0;
		margin-top: 90px;
		.testimonial-item {
			display: flex;
			align-items: center;
			@include breakpoint (xx-small) {
				display: block;
			}
			.testimonial-content {
				padding-left: 20px;
				text-align: center;
				@include breakpoint (xx-small) {
					padding-top: 25px;
				}
				.quote-icon {
					> i {
						font-size: 35px;
					}
				}
				> p {
					&.short-desc {
						margin: 0 auto;
						max-width: 75%;
						font-size: 24px;
						font-weight: 300;
						line-height: 40px;
						@include breakpoint (max-small) {
							max-width: 100%;
							font-size: 18px;
						}
					}
				}
				> .client-info {
					> h3 {
						&.name {
							margin-bottom: 0;
							font-weight: 600;
							font-size: 18px;
							padding-top: 15px;
						}
					}
					> span {
						&.occupation {
							padding-top: 10px;
							display: block;
						}
					}
				}
			}
		}
	}
}
