/*----------------------------------------*/
/*  16 - Components - Shipping
/*----------------------------------------*/
.torress-shipping_area {
	padding-top: 90px;
	.shipping-nav {
		border: 1px solid $border-color;
		padding: 30px 0 25px;
		@include breakpoint(max-medium) {
			padding: 30px 30px 25px;
		}
		.shipping-item {
			display: flex;
			@include breakpoint(max-small) {
				display: block;
				text-align: center;
			}
			.shipping-icon {
				font-size: 40px;
				border-radius: 10px;
				width: 60px;
				height: 60px;
				line-height: 58px;
				display: block;
				text-align: center;
				@include breakpoint(normal) {
					width: 50px;
					height: 50px;
					line-height: 48px;
					font-size: 30px;
				}
				@include breakpoint(max-small) {
					width: auto;
				}
			}
			.shipping-content {
				width: calc(100% - 60px);
				padding-left: 15px;
				@include breakpoint(normal) {
					width: calc(100% - 50px);
					padding-left: 0;
				}
				@include breakpoint(max-small) {
					width: auto;
					padding-left: 0;
				}
				> h6 {
					margin-bottom: 0;
					padding-bottom: 10px;
				}
				> p {
					margin-bottom: 0;
					font-size: 14px;
					@include breakpoint(normal) {
						font-size: 13px;
					}
				}
			}
		}
		[class*="col-"] {
			&:nth-child(-n + 2) {
				@include breakpoint(max-medium) {
					.shipping-item {
						padding-bottom: 30px;
					}
				}
			}
		}
	}
}
